var sr = ScrollReveal();
if (sr.isSupported()) {
  document.documentElement.classList.add('sr');
  sr.reveal('.reveal-this', { duration: 800, distance: '50px', easing: 'ease', scale: '1' });
  sr.reveal('.content', { duration: 800, distance: '50px', easing: 'ease', scale: '1', delay: 300 });
}

$(document).ready(function(){
  $('#sesosmenu .has-sub-label').on('click', function(e){
    unchecked = $(this).parent().parent().find('input')[0].checked;
    if (!unchecked) $(this).find('.submenu-button').addClass('submenu-button-minus');
    else $(this).find('.submenu-button').removeClass('submenu-button-minus');
  });
  $("#sesosmenu #menu").prepend("<div id='menu-line'></div>");
  var $menuLine = $('#sesosmenu #menu-line');

  function setActiveMenuItemWidth(){
    var $el = $('#sesosmenu .main-menu .active')[0];
    var activeElem = $el ? $el : $('#furtherservice').addClass('active');
    var activeWidth = $(activeElem).width();
    var activeLeft = $(activeElem).position().left;
    setMenuLineCss(activeWidth, activeLeft);
  }
  setActiveMenuItemWidth();

  $(window).on('resize', setActiveMenuItemWidth);

  $('#sesosmenu .main-menu > li').on('mouseover', function(){
    var $elem = $(this);
    var hoverWidth = $elem.width();
    var hoverLeft = $elem.position().left;
    setMenuLineCss(hoverWidth, hoverLeft);
  });
  $('#sesosmenu .main-menu > li').on('mouseout', setActiveMenuItemWidth);
  function setMenuLineCss(width, left){
    $menuLine.css('width', width).css('left', left);
  }
  $('.hero-fader').slick({
    lazyLoad: 'ondemand',
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    autoplay: true,
    autoplaySpeed: 4000
  });

  function detectIE() {
      console.log('detectIE');
      var ua = window.navigator.userAgent;

      var msie = ua.indexOf('MSIE ');
      if (msie > 0) {
          // IE 10 or older => return version number
          return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
      }

      var trident = ua.indexOf('Trident/');
      if (trident > 0) {
          // IE 11 => return version number
          var rv = ua.indexOf('rv:');
          return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
      }

      var edge = ua.indexOf('Edge/');
      if (edge > 0) {
         return false;
         // Edge (IE 12+) => return version number
         // return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
      }

      // other browser
      return false;
  }
  if(detectIE()) document.documentElement.classList.add('old-ie-browser');
  $('#close-old-browser-modal').on('click', function(e){
    $('.old-browser-modal').fadeOut();
  });
});
